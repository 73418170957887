import React, { createContext, useMemo, useContext } from 'react';
import usePersistedState from '../hooks/usePersistedState';
import { AREAS_LIST } from '../models/areas.js';

// Create an object of default values with the name and a value of 50
const defaultScores = AREAS_LIST.reduce((values, {name}) => {
  values[name] = 50;
  return values;
}, {});

const scoresContext = createContext();

export function ScoresProvider(props) {
  const [scores, setScores] = usePersistedState(`areaSliders`, defaultScores);
  const contextValue = useMemo(() => ([scores, setScores]), [scores, setScores]);
  return (
    <scoresContext.Provider value={contextValue} {...props}/>
  );
}

export function useScores() {
  return useContext(scoresContext);
}
