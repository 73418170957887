import React, { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { MAX_CHARACTER_COUNT } from '../../constants';
import { AREAS_LIST } from '../../models/areas';
import usePersistedState from '../../hooks/usePersistedState';
import { useScores } from '../../contexts/scoresContext';

import TextareaGroup from '../TextareaGroup';

import { ReactComponent as Arrow } from '../../assets/arrow.svg';

import styles from './AreaDetails.module.scss';

function Score(props) {
  const { t } = useTranslation();
  const ticks = [];
  for (let i = 5; i <= 100; i += 5) ticks.push(i);

  return (
    <label className={styles.score}>
      <span className={styles.label}>{t('common.score')}</span>
      <div className={styles.selectContainer}>
        <select {...props}>
          {ticks.map((tick) => (
            <option key={tick} value={tick}>
              {tick}
            </option>
          ))}
        </select>
        <span
          className={clsx(
            styles.value,
            props.value === '100' && styles.value100
          )}
        >
          {props.value}
        </span>
        <Arrow aria-hidden='true' className={styles.arrow} />
      </div>
    </label>
  );
}

function AreaDetails({ name, title }) {
  const { t } = useTranslation();
  const { color } = AREAS_LIST.find((area) => area.name === name);

  const [scores, setScores] = useScores();
  const [futureScore, setFutureScore] = usePersistedState(
    `${name}FutureScore`,
    50
  );
  const [nowDesc, setNowDesc] = usePersistedState(`${name}NowDesc`, '');
  const [futureDesc, setFutureDesc] = usePersistedState(
    `${name}FutureDesc`,
    ''
  );
  const [focused1, setFocused1] = usePersistedState(`${name}Focused1`, '');
  const [focused2, setFocused2] = usePersistedState(`${name}Focused2`, '');
  const [focused3, setFocused3] = usePersistedState(`${name}Focused3`, '');
  const [tools1, setTools1] = usePersistedState(`${name}Tools1`, '');
  const [tools2, setTools2] = usePersistedState(`${name}Tools2`, '');
  const [tools3, setTools3] = usePersistedState(`${name}Tools3`, '');
  const [aligned1, setAligned1] = usePersistedState(`${name}Aligned1`, '');
  const [aligned2, setAligned2] = usePersistedState(`${name}Aligned2`, '');
  const [aligned3, setAligned3] = usePersistedState(`${name}Aligned3`, '');

  const filled =
    focused1 ||
    focused2 ||
    focused3 ||
    tools1 ||
    tools2 ||
    tools3 ||
    aligned1 ||
    aligned2 ||
    aligned3;

  const myPlanRef = useRef();
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setExpanded(!expanded);
    if (expanded) window.scrollBy(0, -myPlanRef.current.offsetHeight);
  }, [expanded]);

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleBox}>
          <img src={`${name}.svg`} alt='' />
          <h3 className={styles.title}>{title}</h3>
        </div>
        <div className={styles.nowBox}>
          <Score
            value={scores[name]}
            onChange={(e) => setScores({ ...scores, [name]: e.target.value })}
          />
          <div className={styles.descriptionWrapper}>
            <textarea
              className={styles.scoreDescription}
              value={nowDesc}
              onChange={(e) => setNowDesc(e.target.value)}
              rows={3}
              placeholder={t('questions.where.now.placeholder')}
              maxLength={MAX_CHARACTER_COUNT}
            />
            <div className={styles.characterCount}>
              {nowDesc.length}/{MAX_CHARACTER_COUNT}
            </div>
          </div>
        </div>
        <div className={styles.futureBox}>
          <Score
            value={futureScore}
            onChange={(e) => setFutureScore(e.target.value)}
          />
          <div className={styles.descriptionWrapper}>
            <textarea
              className={styles.scoreDescription}
              value={futureDesc}
              onChange={(e) => setFutureDesc(e.target.value)}
              rows={3}
              placeholder={t('questions.where.future.placeholder')}
              maxLength={MAX_CHARACTER_COUNT}
            />
            <div className={styles.characterCount}>
              {futureDesc.length}/{MAX_CHARACTER_COUNT}
            </div>
          </div>
        </div>
      </div>

      <div
        ref={myPlanRef}
        className={clsx(styles.myPlan, !expanded && styles.hidden)}
      >
        <h4 className={styles.subsectionTitle}>
          {t('questions.sections.Focused.title')}
        </h4>

        <TextareaGroup
          placeholder={t('questions.sections.Focused.questions.0.placeholder')}
          label={t('questions.sections.Focused.questions.0.question')}
          value={focused1}
          setValue={setFocused1}
          maxLength={MAX_CHARACTER_COUNT}
        />

        <TextareaGroup
          placeholder={t('questions.sections.Focused.questions.1.placeholder')}
          label={t('questions.sections.Focused.questions.1.question')}
          value={focused2}
          setValue={setFocused2}
          maxLength={MAX_CHARACTER_COUNT}
        />

        <TextareaGroup
          placeholder={t('questions.sections.Focused.questions.2.placeholder')}
          label={t('questions.sections.Focused.questions.2.question')}
          value={focused3}
          setValue={setFocused3}
          maxLength={MAX_CHARACTER_COUNT}
        />

        <h4 className={styles.subsectionTitle}>
          {t('questions.sections.Tools.title')}
        </h4>

        <TextareaGroup
          placeholder={t('questions.sections.Tools.questions.0.placeholder')}
          label={t('questions.sections.Tools.questions.0.question')}
          value={tools1}
          setValue={setTools1}
          maxLength={MAX_CHARACTER_COUNT}
        />

        <TextareaGroup
          placeholder={t('questions.sections.Tools.questions.1.placeholder')}
          label={t('questions.sections.Tools.questions.1.question')}
          value={tools2}
          setValue={setTools2}
          maxLength={MAX_CHARACTER_COUNT}
        />

        <TextareaGroup
          placeholder={t('questions.sections.Tools.questions.2.placeholder')}
          label={t('questions.sections.Tools.questions.2.question')}
          value={tools3}
          setValue={setTools3}
          maxLength={MAX_CHARACTER_COUNT}
        />

        <h4 className={styles.subsectionTitle}>
          {t('questions.sections.Aligned.title')}
        </h4>

        <TextareaGroup
          placeholder={t('questions.sections.Aligned.questions.0.placeholder')}
          label={t('questions.sections.Aligned.questions.0.question')}
          value={aligned1}
          setValue={setAligned1}
          maxLength={MAX_CHARACTER_COUNT}
        />

        <TextareaGroup
          placeholder={t('questions.sections.Aligned.questions.1.placeholder')}
          label={t('questions.sections.Aligned.questions.1.question')}
          value={aligned2}
          setValue={setAligned2}
          maxLength={MAX_CHARACTER_COUNT}
        />

        <TextareaGroup
          placeholder={t('questions.sections.Aligned.questions.2.placeholder')}
          label={t('questions.sections.Aligned.questions.2.question')}
          value={aligned3}
          setValue={setAligned3}
          maxLength={MAX_CHARACTER_COUNT}
        />
      </div>

      <button
        className={clsx(
          styles.expandButton,
          (filled || expanded) && styles.light,
          expanded && styles.expandedMargin
        )}
        onClick={toggleExpand}
        style={{ background: color }}
        aria-expanded={expanded}
      >
        {expanded && t('common.collapse')}

        {!expanded &&
          (filled
            ? t('questions.actions.edit-plan')
            : t('questions.actions.create-plan'))}
      </button>
    </section>
  );
}

export default AreaDetails;
