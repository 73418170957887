import { useState, useCallback } from "react";

import { v4 as uuidv4 } from 'uuid';

const localStorageItem = 'dataV0';

function load(stateName) {
  return JSON.parse(localStorage.getItem(localStorageItem))?.[stateName];
}

export function getData() {
  return JSON.parse(localStorage.getItem(localStorageItem)) || {};
}

function persist(stateName, state) {
  const data = getData();
  data[stateName] = state;

  if (!data.userId) {
    data.userId = uuidv4();
  }

  localStorage.setItem(localStorageItem, JSON.stringify(data));
}

export default function usePersistedState(stateName, initialState) {
  const [state, setState] = useState(() => load(stateName) || initialState);

  const persistedSetState = useCallback(state => {
    // Call setState as first thing to improve UI responsiveness
    setState(state);
    persist(stateName, state);
  }, [stateName]);

  return [state, persistedSetState];
}
