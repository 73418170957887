import React from 'react';

import { ReactComponent as Icon } from './icon.svg';

import styles from './Instruction.module.scss';

function Instruction({ cite, instruction, ...props }) {
  return (
    <div className={styles.wrapper}>
      <Icon />
      <p>{instruction}</p>
    </div>
  );
}

export default Instruction;
