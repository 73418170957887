import config from '../config';

export const submitEntry = async (data) => {
  try {
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    await fetch(config.SUBMIT_ENTRY_ENDPOINT, fetchOptions);

    console.log('Success');
  } catch (e) {
    console.error(e);
  }
};
