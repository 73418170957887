import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useScores } from '../../contexts/scoresContext';
import { AREAS_LIST } from './../../models/areas.js';

import Wheel from '../Wheel';

import styles from './AreaSliders.module.scss';

const MIN_SLIDER_VALUE = 5;

function AreaSlider() {
  const { t } = useTranslation();
  const [values, setValues] = useScores();

  const onChange = useCallback(
    (name, newValue) => {
      if (newValue < MIN_SLIDER_VALUE) return; // Limits the user from saying they are 0% good at something

      setValues({
        ...values,
        [name]: newValue,
      });
    },
    [values, setValues]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.sliders}>
        {AREAS_LIST.map(({ name, color }, index) => {
          const labelId = `${name}-wheel-slider`;
          const value = values[name];

          return (
            <div className={styles.fieldset} key={name}>
              <label className={styles.label} htmlFor='id' id={labelId}>
                {t(`areas-list.${index}.title`)}
              </label>
              <div className={styles.slider} style={{ color }}>
                <span
                  className={styles.bar}
                  aria-hidden='true'
                  style={{
                    // 2px here comes from the offset to allow for the border on the rail
                    width: `calc(${value}% + ${1 - value / 100}rem + 2px)`,
                  }}
                >
                  <span className={styles.tooltip}>{value}</span>
                </span>
                <input
                  className={styles.input}
                  type='range'
                  min={0}
                  max={100}
                  step={5}
                  value={value}
                  aria-labelledby={labelId}
                  onChange={(e) => onChange(name, e.target.value)}
                />
              </div>
            </div>
          );
        })}
      </div>

      <Wheel className={styles.wheel} values={values} />
    </div>
  );
}

export default AreaSlider;
