import React, { useMemo } from 'react';
import clsx from 'clsx';

import { ReactComponent as IconArrow } from '../../assets/arrow.svg';

import styles from './Select.module.scss';

const Select = ({ value, options, onChange, label, className }) => {
  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [value, options]
  );

  return (
    <label className={styles.field}>
      <span>{label}</span>
      <div className={clsx(styles.selectContainer, className)}>
        <select value={value} onChange={onChange}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
        <div>{selectedOption.label}</div>
        <IconArrow role='presentation' />
      </div>
    </label>
  );
};

export default Select;
