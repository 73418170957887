import React from 'react';
import clsx from 'clsx';

import styles from './Wheel.module.css';

import { AREAS_LIST } from './../../models/areas.js';

const totalAreas = AREAS_LIST.length;
const areasColors = AREAS_LIST.reduce((acc, {name, color}) => {
  acc[name] = color;
  return acc;
}, {});

function Wheel({ values, className,...props }) {
  return (
    <div className={clsx(className, styles.wheel)}>
      <svg className={styles.chunk} width="440px" height="440px" viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <g id="overlay">
            {/* White line (left of wedge) */}
            <rect x="219" y="20" height="200" width="2" fill="#FFF" />

            {/* 100% circle */}
            <circle cx="220" cy="20" r="10" fill="#FFF" />
            <g transform="translate(210, 10)">
              <path d="M5.956 6v7.512H4.864V6.876H4V6h1.956zM9.608 12.288V7.224c0-.144-.024-.236-.072-.276-.048-.048-.144-.072-.288-.072H8.6c-.144 0-.24.024-.288.072-.04.04-.06.132-.06.276v5.064c0 .144.02.24.06.288.048.04.144.06.288.06h.648c.144 0 .24-.02.288-.06.048-.048.072-.144.072-.288zM10.7 7.056v5.4c0 .704-.372 1.056-1.116 1.056H8.276c-.752 0-1.128-.352-1.128-1.056v-5.4C7.148 6.352 7.524 6 8.276 6h1.308c.744 0 1.116.352 1.116 1.056zM14.284 12.288V7.224c0-.144-.024-.236-.072-.276-.048-.048-.144-.072-.288-.072h-.648c-.144 0-.24.024-.288.072-.04.04-.06.132-.06.276v5.064c0 .144.02.24.06.288.048.04.144.06.288.06h.648c.144 0 .24-.02.288-.06.048-.048.072-.144.072-.288zm1.092-5.232v5.4c0 .704-.372 1.056-1.116 1.056h-1.308c-.752 0-1.128-.352-1.128-1.056v-5.4c0-.704.376-1.056 1.128-1.056h1.308c.744 0 1.116.352 1.116 1.056z" fill="#000"/>
            </g>
          </g>
        </defs>
        <mask id="wedge-mask" fill="white">
          <path transform="translate(20, 20)" d="M112.33 20.241a200.006 200.006 0 01173.551-.865L200.004 200 112.33 20.241z" fillRule="nonzero"/>
        </mask>

        <circle cx="220" cy="220" r="200" fill="#EFEFEF" stroke="#E3E3E3" strokeWidth="1" />

        {Object.entries(values).map(([id, value], index) => (
          <g key={`wedge-${index}`} transform={`rotate(${((360 / totalAreas) * index).toFixed(4)}, 220, 220)`}>
            <g mask="url(#wedge-mask)">
              <circle className={styles.wedge} cx="220" cy="220" r="200" fill={areasColors[id]} style={{ transform: `scale(${value / 100})` }} />
            </g>
          </g>
        ))}

        {Object.keys(values).map((key, index) => (
          <use xlinkHref="#overlay" key={`overlay-${index}`} transform={`rotate(${((360 / totalAreas) * (index - 0.5)).toFixed(4)}, 220, 220)`} />
        ))}

        {/* 0% circle */}
        <g transform="translate(206, 206)">
          <circle cx="14" cy="14" r="14" fill="#fff"/>
          <path d="M14.69 17.432V9.836c0-.216-.036-.354-.108-.414-.072-.072-.216-.108-.432-.108h-.972c-.216 0-.36.036-.432.108-.06.06-.09.198-.09.414v7.596c0 .216.03.36.09.432.072.06.216.09.432.09h.972c.216 0 .36-.03.432-.09.072-.072.108-.216.108-.432zm1.638-7.848v8.1c0 1.056-.558 1.584-1.674 1.584h-1.962c-1.128 0-1.692-.528-1.692-1.584v-8.1C11 8.528 11.564 8 12.692 8h1.962c1.116 0 1.674.528 1.674 1.584z" fill="#000"/>
        </g>
      </svg>
    </div>
  );
}

export default Wheel;
