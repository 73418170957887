export const QUESTIONS = {
  where: {
    now: {
      question: 'Where am I\xa0now',
      placeholder: 'Describe where you are now',
    },
    future: {
      question: 'Where I want to\xa0be',
      placeholder: 'Describe where you want to be',
    },
  },
  sections: {
    Focused: {
      title: 'I. Get Focused and Clear',
      questions: [
        {
          question: 'Total Honesty about where you are:',
          placeholder: 'Write a few additional words to describe where you are now in this area. The more honest you can be about where you are, the clearer you can be about where you want to\xa0be.',
        },
        {
          question: 'Clear & compelling vision:',
          placeholder: 'What specific, achievable outcomes do you want for this area of your life? Avoid being vague and think about measurable\xa0results.',
        },
        {
          question: 'Strong reasons to follow through:',
          placeholder: 'Why do you want it? What emotional juice is going to drive you to achieve your vision, no matter\xa0what?',
        },
      ],
    },
    Tools: {
      title: 'II. Get the Best Tools for Success',
      questions: [
        {
          question: 'What resources are available to you to help close the gap (books, training programs, coaching, virtual events,\xa0etc.)?',
          placeholder: 'Progress is only possible when you have a map that details how to get from where you are to where you want to\xa0be.',
        },
        {
          question: 'Who are some possible mentors or coaches for you in this area? Who can help hold you accountable? What peer group do you need to be\xa0around?',
          placeholder: 'Once you have a map, you need someone to interpret that map and a guide you to follow it. Remember, success leaves clues. Find an extraordinary mentor who has already achieved the result you\xa0desire.',
        },
        {
          question: 'What rituals do you need to establish so that you have a daily discipline to follow through? What actions do you need to take each\xa0day?',
          placeholder: 'Standards make change possible; rituals make it real. Change will only last long term when it becomes a MUST for you, when you make it a daily discipline to condition the changs and follow through, and when you raise your standards so that the changes become part of your\xa0identity.',
        },
      ],
    },
    Aligned: {
      title: 'III. Get Aligned, Get Integrated & Get Results',
      questions: [
        {
          question: 'What disempowering beliefs do you currently have about this area of your life?',
          placeholder: 'Could you have the tools to achieve what you want but still fail? Absolutely. There may be conflicting values in your blueprint that hold you\xa0back.',
        },
        {
          question: 'What are some empowering beliefs you have, or could have, about this area of your life moving forward?',
          placeholder: 'Install a new blueprint for yourself that is in sync with your goal. Only when you are aligned will you have the fire to go after what you\xa0want.',
        },
        {
          question: 'When you are at your best in this area, who are you? What is your empowering identity for when this area is where you want it to\xa0be?',
          placeholder: 'Describe yourself in a way that calls to the strength inside of you to achieve your outcome. Give this identity a name that fills you with energy when you take on that\xa0spirit. ',
        },
      ],
    },
  },
  vision: {
    vision: {
      question: 'What does an extraordinary life mean for you today?',
      placeholder: 'Write a few additional words to describe where you are now in this area.',
    },
    blockers: {
      question: 'What’s preventing you from having it?',
      placeholder: 'Write a few additional words to describe where you are now in this area.',
    },
  },
};
