import React from 'react';
import { useTranslation } from 'react-i18next';

import { MAX_CHARACTER_COUNT } from '../../constants';
import usePersistedState from '../../hooks/usePersistedState';

import TextareaGroup from '../TextareaGroup';

function Vision() {
  const { t } = useTranslation();
  const [vision, setVision] = usePersistedState('vision', '');
  const [blockers, setBlockers] = usePersistedState('blockers', '');

  return (
    <div className='container'>
      <TextareaGroup
        label={t('questions.vision.vision.question')}
        placeholder={t('questions.vision.vision.placeholder')}
        value={vision}
        setValue={setVision}
        maxLength={MAX_CHARACTER_COUNT}
      />

      <TextareaGroup
        label={t('questions.vision.blockers.question')}
        placeholder={t('questions.vision.blockers.placeholder')}
        value={blockers}
        setValue={setBlockers}
        maxLength={MAX_CHARACTER_COUNT}
      />
    </div>
  );
}

export default Vision;
