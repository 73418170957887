import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';

import usePersistedState from '../../hooks/usePersistedState';

import { ReactComponent as IconPrivacyTick } from './privacy-tick.svg';
import { ReactComponent as IconSuccess } from './success.svg';
import { ReactComponent as IconArrow } from '../../assets/arrow.svg';

import styles from './ContactInfoForm.module.scss';

const SUCCESS_NOTIFICATION_DURATION = 5000;

const LANGUAGES = ['Chinese', 'Italian', 'Japanese', 'Russian'].sort();

function CountryOfResidence() {
  const { t } = useTranslation();
  const [country, setCountry] = usePersistedState(`countryOfResidence`, '');

  return (
    <label className={styles.field}>
      <span>{t('contact-info.country-label')}</span>
      <input
        type='text'
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        required
      />
    </label>
  );
}

function ContactInfoForm({ showLanguageField, onSubmit, submitLabel }) {
  const { t } = useTranslation();
  const [language, setLanguage] = usePersistedState(`language`, 'en');
  const [fullName, setFullName] = usePersistedState(`fullName`, '');
  const [email, setEmail] = usePersistedState(`email`, '');
  const [phone, setPhone] = usePersistedState(`phone`, '');
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (!showLanguageField) return;

    // <select> can't be empty, so when the form loads it is set on the first
    // of its options. But if the user clicks the submit button without changing
    // it, setLanguage is never called and the language is never written in the
    // local storage, causing a failure in the onSubmit handlers that expect all
    // the data to be there instead. For this reason, when no language is found
    // in the local storage we immediately write the default one, so the form
    // can work even if the <select> is left untouched.
    if (!language) {
      setLanguage(LANGUAGES[0]);
    }
  }, [showLanguageField, language, setLanguage]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault(); // This prevents the form from reloading the page
      try {
        setLoading(true);
        await onSubmit();
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), SUCCESS_NOTIFICATION_DURATION);
      } catch (err) {
        alert(t('error.something-went-wrong'));
        console.error('Submit error', err);
      } finally {
        setLoading(false);
      }
    },
    [onSubmit, t]
  );

  return (
    // Using the onSubmit of the form instead of the click of the button to
    // leverage the automatic HTML5 form validation
    <form onSubmit={handleSubmit}>
      {showLanguageField && (
        <>
          <label className={styles.field}>
            <span>{t('common.language')}</span>
            <div className={styles.selectContainer}>
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                {LANGUAGES.map((lang) => (
                  <option key={lang} value={lang}>
                    {t(`languages.${lang}`)}
                  </option>
                ))}
              </select>
              <div>{language}</div>
              <IconArrow role='presentation' />
            </div>
          </label>
          <CountryOfResidence />
        </>
      )}
      <label className={styles.field}>
        <span>{t('contact-info.full-name')}</span>
        <input
          type='text'
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
      </label>
      <label className={styles.field}>
        <span>{t('contact-info.email-address')}</span>
        <input
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </label>
      <label className={styles.field}>
        <span>{t('contact-info.phone-number')}</span>
        <input
          type='tel'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
      </label>

      {/* When showLanguageField is disabled, Country of Residence is the last
          field */}
      {!showLanguageField && <CountryOfResidence />}

      <label className={styles.privacy}>
        <span
          className={clsx(styles.checkbox, privacyChecked && styles.checked)}
        >
          <IconPrivacyTick role='presentation' />
          <input
            type='checkbox'
            checked={privacyChecked}
            onChange={() => setPrivacyChecked(!privacyChecked)}
            required
          />
        </span>

        <div>
          <Trans t={t} i18nKey='contact-info.legal'>
            I've read and accept the{' '}
            <a
              href='https://www.tonyrobbins.com/privacy-policy/'
              rel='noopener noreferrer'
              target='_blank'
            >
              Privacy Policy
            </a>{' '}
            and the Terms and Conditions:
          </Trans>
          <div className={styles.termsCondition}>
            <Trans t={t} i18nKey='contact-info.terms'>
              No purchase necessary to enter or to win. Must be 18 or older.
              Void where prohibited by law. For the Grand Prize and the First
              Prize, winner must also be a legal resident of the United States.
              To enter, you will be provided access to a video, and after
              reviewing the video, complete the Gap Map homework between
              Thursday, May 27, 2021, at 9:00 am EDT and Wednesday, June 2,
              2021. Entries for the Grand Prize drawing must be submitted by
              1:00 pm Eastern Daylight Time on June 2, 2021. Read the{' '}
              <a
                href={t('contact-info.terms-link')}
                rel='noopener noreferrer'
                target='_blank'
              >
                Complete and Official Rules
              </a>
              .
            </Trans>
          </div>
        </div>
      </label>
      <div className={styles.submitArea}>
        <button
          className={clsx(
            styles.generateButton,
            loading && styles.generateButtonLoading
          )}
          disabled={!privacyChecked || loading}
        >
          {loading ? t('contact-info.sending-data') : submitLabel}
        </button>
        <div className={styles.success} hidden={!showSuccess}>
          <IconSuccess role='presentation' />
          {t('contact-info.success')}
        </div>
      </div>
    </form>
  );
}

export default ContactInfoForm;
