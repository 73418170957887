export class ParameterError extends Error {
  constructor(obj, message) {
    // obj is meant to be a quick way to pass both param name and value, if
    // you use it this way: new ParameterError({param}, 'error!');
    const [paramName, paramValue] = Object.entries(obj)[0];
    super(
      `Error on parameter \`${paramName}\` with value \`${paramValue}\` (${typeof paramValue}): ${message}`
    );
  }
}

// Allows better debugging to ensure that calls aren't made to services that don't exist
const checkAnalyticsProvider = () => {
  if (window.analytics) return true;
  return false;
};

export function trackEvent(eventName, properties = {}, callback = null) {
  if (!checkAnalyticsProvider()) return;

  if (!eventName)
    throw new ParameterError({ eventName }, 'Event name is required.');
  if (typeof properties !== 'object')
    throw new ParameterError({ properties }, 'Must be an object.');
  if (callback !== null && typeof callback !== 'function')
    throw new ParameterError({ callback }, 'Must be null or a function');

  window.analytics.track(eventName, properties);

  if (callback) callback();
}
