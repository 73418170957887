export const AREAS_LIST = [
  {
    name: 'body',
    title: 'Physical Body',
    color: '#FF5C00',
  },
  {
    name: 'meaning',
    title: 'Emotions & Meaning',
    color: '#9245FF',
  },
  {
    name: 'relationships',
    title: 'Relationships',
    color: '#FF234B',
  },
  {
    name: 'time',
    title: 'Time',
    color: '#357AFF',
  },
  {
    name: 'career',
    title: 'Work\u200A/\u200ACareer\u200A/\u200AMission',
    color: '#E5AC00',
  },
  {
    name: 'finances',
    title: 'Finances',
    color: '#00AB3A',
  },
  {
    name: 'contribute',
    title: 'Celebrate & Contribute',
    color: '#00A9B5',
  },
];
