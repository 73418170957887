import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Hero.module.scss';

function Hero() {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className='group'>
        <h1 className={styles.title}>
          <span className={styles.h1}>{t('common.gap-map')}</span>
          <span className='sr-only'>{t('common.by')}</span>
          <span className={styles.lead}>{t('common.tony-robbins')}</span>
        </h1>
      </div>
    </div>
  );
}

export default Hero;
