import React, { useState, useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Translations from '../Translations';
import AreaSliders from '../AreaSliders';
import AreaDetails from '../AreaDetails';
import Hero from '../Hero';
import Blockquote from '../Blockquote';
import Vision from '../Vision';
import Instruction from '../Instruction';
import ContactInfoForm from '../ContactInfoForm';
import Select from '../Select/Select';

import Pyramid from '../../assets/pyramid.svg';
import PyramidES from '../../assets/pyramid-es.svg';
import PyramidFA from '../../assets/pyramid-fa.svg';
import PyramidJA from '../../assets/pyramid-ja.svg';
import PyramidRU from '../../assets/pyramid-ru.svg';
import PyramidIT from '../../assets/pyramid-it.svg';
import PyramidZH from '../../assets/pyramid-zh.svg';

import { AREAS_LIST } from '../../models/areas.js';

import usePersistedState, { getData } from '../../hooks/usePersistedState.js';
import { trackEvent } from '../../services/AnalyticsService';
import { generatePdf } from '../../services/PdfService.js';
import { ScoresProvider } from '../../contexts/scoresContext';

import { ReactComponent as IconTranslations } from './translations.svg';

import styles from './App.module.scss';

const LANGUAGES = [
  { value: 'zh', label: 'Chinese' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ru', label: 'Russian' },
  { value: 'en', label: 'English' },
  { value: 'fa', label: 'Farsi' },
  { value: 'es', label: 'Spanish' },
].sort((a, b) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
});

const PyramidLanguageAsset = {
  en: Pyramid,
  it: PyramidIT,
  zh: PyramidZH,
  ru: PyramidRU,
  ja: PyramidJA,
  fa: PyramidFA,
  es: PyramidES,
};

function App() {
  const { t, i18n } = useTranslation();
  const [showTranslations, setShowTranslations] = useState(false);
  const [language, setLanguage] = usePersistedState(`language`, 'en');

  const isLanguageCode = LANGUAGES.map(({ value }) => value).includes(language); // Old data may contain a language setting that is not the language code.

  const PyramidImageAsset = PyramidLanguageAsset[language];

  const handleGeneratePdf = useCallback(async () => {
    await generatePdf(getData());
    trackEvent('Export PDF Clicked', {
      language,
    });
  }, [language]);

  const handleLanguageChange = useCallback(
    (e) => {
      const lng = e.target.value;
      i18n.changeLanguage(lng).then(() => {
        setLanguage(lng);
      });

      trackEvent('User select language', {
        language: lng,
      });
    },
    [i18n, setLanguage]
  );

  useEffect(() => {
    const direction = i18n.dir();
    document.body.dir = direction;
  }, [language, i18n]);

  if (showTranslations) {
    return <Translations onBackClick={() => setShowTranslations(false)} />;
  }

  return (
    <>
      <Hero />

      <div className={styles.translationsBanner}>
        <div className={styles.languageLabel}>
          <IconTranslations role='presentation' />
          <Trans t={t} i18nKey='main.available-translations'>
            Non-English speaker?
          </Trans>
        </div>
        <div className={styles.selectWrapper}>
          <Select
            className={styles.languageSelect}
            value={isLanguageCode ? language : 'en'}
            label={t('common.language')}
            onChange={handleLanguageChange}
            options={LANGUAGES.map((lang) => {
              return { value: lang.value, label: t(`languages.${lang.label}`) };
            })}
          ></Select>
        </div>
      </div>

      <div className='group'>
        <Blockquote cite='Tony Robbins'>
          <p>
            <Trans t={t} i18nKey='main.change-quote'>
              “Change is automatic, Progress is&nbsp;not.
              <br /> Progress is the result of conscious thought, decision
              and&nbsp;action.”
            </Trans>
          </p>
        </Blockquote>

        <h2>{t('pyramid.title')}</h2>

        <div className={styles.pyramidWrapper}>
          <div>
            <p className={styles.intro}>{t('pyramid.intro')}</p>
            <p>{t('pyramid.copy.0')}</p>
            <p>{t('pyramid.copy.1')}</p>
          </div>
          <img className={styles.pyramidImage} src={PyramidImageAsset} alt='' />
        </div>

        <hr />

        <ScoresProvider>
          <h2>{t('wheel.title')}</h2>
          <p>{t('wheel.copy')}</p>
          <Instruction instruction={t('wheel.instructions')} />

          <AreaSliders />

          <hr />

          <h2>{t('gapmap.title')}</h2>
          <p>{t('gapmap.copy.0')}</p>
          <p>{t('gapmap.copy.1')}</p>
          <Instruction instruction={t('gapmap.instructions')} />

          <div className={styles.columnsHeadings} aria-hidden='true'>
            <div className={styles.columnTitle}>{t('gapmap.categories')}</div>
            <div className={styles.columnNow}>
              {t('questions.where.now.question')}
            </div>
            <div className={styles.columnFuture}>
              {t('questions.where.future.question')}
            </div>
          </div>
          {AREAS_LIST.map(({ name }, index) => (
            <AreaDetails
              key={name}
              name={name}
              title={t(`areas-list.${index}.title`)}
            />
          ))}
        </ScoresProvider>

        <hr />

        <h2>{t('vision.title')}</h2>

        <p>{t('vision.copy.0')}</p>
        <p>{t('vision.copy.1')}</p>

        <Vision />

        <hr />

        <div className='sectionWithForm'>
          <div>
            <h2>{t('wrapping-up.title')}</h2>
            <p>{t('wrapping-up.copy.0')}</p>
            <p>{t('wrapping-up.copy.1')}</p>
          </div>
          <div className='container'>
            <ContactInfoForm
              showLanguageField={false}
              onSubmit={handleGeneratePdf}
              submitLabel={t('contact-info.submit')}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
