import React from 'react';

import styles from './Blockquote.module.scss';

function Blockquote({ cite, children, ...props }) {
  return (
    <blockquote className={styles.wrapper}>
      {children}
      {cite && <cite className={styles.cite}>{cite}</cite>}
    </blockquote>
  );
}

export default Blockquote;
