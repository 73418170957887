import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getData } from '../../hooks/usePersistedState';
import { submitEntry } from '../../services/EntryService';

import ContactInfoForm from '../ContactInfoForm/ContactInfoForm';

import { ReactComponent as IconBack } from './back.svg';

import styles from './Translations.module.scss';

function Translations({ onBackClick }) {
  const { t } = useTranslation();

  const handleSubmit = useCallback(async () => {
    const { fullName, email, phone, language, countryOfResidence } = getData();
    const exportData = { fullName, email, phone, language, countryOfResidence };
    await submitEntry(exportData);

    const languageSlug = language.toLowerCase();
    const link = document.createElement('a');
    link.href = `translations/${languageSlug}.pdf`;
    link.download = `gap-map-${languageSlug}`;
    link.click();
  }, []);

  return (
    <div className={clsx('group', styles.mainContainer)}>
      <div className='sectionWithForm'>
        <div>
          <button onClick={onBackClick} className={styles.backButton}>
            <IconBack role='presentation' />
            {t('translations.back')}
          </button>

          <h2>{t('translations.title')}</h2>

          <p>{t('translations.copy.0')}</p>
          <p>{t('translations.copy.1')}</p>
        </div>

        <div className='container'>
          <ContactInfoForm
            showLanguageField={true}
            onSubmit={handleSubmit}
            submitLabel={t('contact-info.download')}
          />
        </div>
      </div>
    </div>
  );
}

export default Translations;
