import React from 'react';
import clsx from 'clsx';

import styles from './TextareaGroup.module.scss';

function TextareaGroup({
  label,
  placeholder,
  value,
  setValue,
  className,
  maxLength,
}) {
  return (
    <label className={clsx(styles.group, className)}>
      <span>{label}</span>
      <textarea
        rows={3}
        value={value}
        maxLength={maxLength}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
      />
      {maxLength && (
        <div className={styles.characterCount}>
          {value.length}/{maxLength}
        </div>
      )}
    </label>
  );
}

export default TextareaGroup;
